<template>
  <div flex="1" bg="white">
    <div class="relative top-0" h="56">
      <div
        v-if="pageStyle.bgColor"
        w="full"
        h="56"
        class="absolute"
        :style="{
          background: 'center center no-repeat',
          backgroundSize: 'cover',
          backgroundColor: 'var(--tc-color-bg-index)',
          backgroundImage: `url(${pageStyle.bgUrl})`,
        }"
      >
      </div>
      <img v-else class="absolute" h="56" src="~assets/images/home/mobile_bg.png" object="cover" w="full" />

      <van-sticky :offset-top="0" :z-index="2">
        <MobileNavBar :theme="isArriveTop ? 'white' : 'green'" />
      </van-sticky>

      <div class="relative" mt="6" px="6">
        <h1 class="whitespace-pre-wrap" font="500" text="white 2xl" leading="9" :style="{ color: 'var(--tc-color-title-index)' }" line-clamp="2">
          {{ pageTitle }}
        </h1>
        <h2 v-if="subTitle" class="mt-2 whitespace-pre-wrap" font="300" text="sm" :style="{ color: 'var(--tc-color-subtitle-index)' }" line-clamp="2">
          {{ subTitle }}
        </h2>
      </div>
    </div>

    <!-- 搜索框 -->
    <MobileHomeSearchInput />

    <!-- 选择频道 -->
    <BusinessBChannel />

    <!-- 最近浏览 -->
    <MobileRecentlyViewed />

    <!-- 热门目的地 -->
    <MobilePopularDestinations />

    <!-- 合作品牌 -->
    <BusinessBPartnerBrands />

    <!-- 推荐行程 -->
    <MobileIntelRecommend />

    <!-- 如何赚取 -->
    <BusinessBHowToEarn />

    <div py="4" px="4">
      <img src="~/assets/icons/azgoxplore-green.png" h="9" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
  ogImageUrl,
})

const { scrollContainer } = useScrollContainer()
const commonDataStore = useCommonStore()
const pageStyle = computed(() => {
  const { indexPageStyle } = commonDataStore.commonData?.partnerInfo?.distStyle || {}

  return indexPageStyle ?? {}
})

const { y } = useScroll(scrollContainer)
const isArriveTop = computed(() => y.value <= 0)

const { pageTitle, subTitle } = usePartnerConfig()
</script>
